<template lang="pug">
div
  .page-header
    .row
      .col-md-5
        h1 {{cliente}}
      .col-md-7
  form(@submit.prevent='gravar')
    vue-element-loading(:active='isActive', spinner='bar-fade-scale', color='#428BCA')
    .form-horizontal
      .form-group
        .col-sm-3.col-sm-3.control-label.no-padding-right(for='contrato') Contrato
        .col-sm-4
          | {{ venda.contrato }}
      .form-group
        select-form(v-model='venda.tipo_emprestimo', name='Tipo de Emprestimo', url='/tipo-emprestimo', id='id', titulo='nome', layout='true', :required='true', texto='true')
      .form-group
        select-form(v-model='venda.orgao', name='Orgão', url='/orgao', id='id', titulo='nome', layout='true', :required='true', texto='true')
      .form-group
        label.col-sm-3.control-label.no-padding-right(for='valor_financ') Valor do Financiamento
        .col-sm-4
          | {{ venda.valor_financ | moneyFormat }}
      legend Parcelamento
      .form-group
        label.col-sm-3.control-label.no-padding-right(for='parc_valor') Valor do Parcelamento
        .col-sm-4
          | {{ venda.parc_valor | moneyFormat }}
      .form-group
        label.col-sm-3.control-label.no-padding-right(for='parc_qtd_parcelas') Quantidade de parcelas
        .col-sm-4
          | {{ venda.parc_qtd_parcelas }}
      .form-group
        label.col-sm-3.control-label.no-padding-right(for='parc_qtd_taxa') Taxa
        .col-sm-4
          | {{ venda.parc_qtd_taxa }}
      .form-group
        label.col-sm-3.control-label.no-padding-right(for='parc_qtd_banco') Banco
        .col-sm-4
          | {{ venda.parc_qtd_banco }}

      .form-group
        label.col-sm-3.control-label.no-padding-right(for='obs')  Obs
        .col-sm-9
          | {{ venda.obs }}
      legend Dados Bancários
      .form-group
        div
          select-form(request='true', v-model='venda.tipo_conta', name='Tipo de Conta', url='/tipo-conta', id='id', titulo='nome', layout='true', :required='true', texto='true')
      .form-group
        label.col-sm-3.control-label.no-padding-right(for='banco') Banco
        .col-sm-4
          | {{ venda.banco }}
      .form-group
        label.col-sm-3.control-label.no-padding-right(for='agencia') Agencia
        .col-sm-4
          | {{ venda.agencia }}
      .form-group
        label.col-sm-3.control-label.no-padding-right(for='conta') Conta
        .col-sm-4
          | {{ venda.conta }}
      .form-group
        label.col-sm-3.control-label.no-padding-right(for='dt_venda')  Data da Venda
        .col-sm-4
          | {{ venda.dt_venda | dataFormat }}
      .form-group
        select-form(request='true', v-model='venda.status_id', name='Status', url='/status/vendas', id='id', titulo='nome', layout='true', :required='true', texto='true')
      .form-group(v-if='user.id_grupo == 1 || user.id_grupo == 3')
        select-form(request='true', v-model='venda.usu_id2', name='Vendedor', url='/usuarios', id='id', titulo='name', layout='true', :required='true', texto='true')

</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import VueElementLoading from 'vue-element-loading'
import {Portuguese} from 'flatpickr/dist/l10n/pt'
import navPills from '@/components/NavPills'
import CartForm from '@/components/form/CartForm'
import SelectForm from '@/components/form/SelectForm'
import req from '@/components/request'
import { erro } from '@/components/Message'
import Permission from '@/mixins/Permission'
import moment from 'moment'
import 'moment/locale/pt-br'
import numeral from 'numeral'
import 'numeral/locales/pt-br'

numeral.locale('pt-BR')

export default {
  components: {
    navPills,
    CartForm,
    SelectForm,
    VueElementLoading,
  },
  filters: {
    dataFormat (x) {
      return moment(x).format('L')
    },
    moneyFormat (x) {
      return numeral(Number(x)).format('$ 0,0.00')
    }
  },
  mixins: [Permission],
  computed: {
    ...mapGetters([
      'user'
    ]),
    total () {
      let totalgeral = 0
      for (var idx in this.produtosSelecionados) {
        let currentItem = this.produtosSelecionados[idx]
        totalgeral += parseInt(currentItem.quant) * currentItem.preco
      }
      return totalgeral
    }
  },
  data () {
    return {
      envio: 'Venda',
      isActive: false,
      cliente: '',
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altInput: true,
        altFormat: 'd F Y',
        dateFormat: 'Y-m-d',
        locale: Portuguese
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false
      },
      venda: {
        contrato: '',
        obs: null,
        tipo_emprestimo: '',
        orgao: '',
        valor_financ: '',
        parc_valor: '',
        parc_qtd_parcelas: null,
        parc_qtd_taxa: null,
        parc_qtd_banco: null,
        status_id: null,
        banco: null,
        agencia: null,
        conta: null,
        tipo_conta: null,
        vendedor: null,
        usuario_id: 0,
        clientes_id: 0,
        dt_venda: null
      },
      produtosSelecionados: [],
      select: []
    }
  },
  methods: {
    ...mapActions([
      'getLogsTotal'
    ]),
    async getVenda () {
      this.venda = await req(`/vendas/${this.$route.params.idVenda}`)
      this.venda.valor_financ = this.venda.valor_financ ? parseFloat(this.venda.valor_financ) : 0
      this.venda.parc_valor = this.venda.parc_valor ? parseFloat(this.venda.parc_valor) : 0
      this.venda.usu_id2 = this.venda.usu_id
      this.isActive = false

    },
    print () {
      window.print()
    },
    getCliente () {
      req(`/clientes/nome/${this.$route.params.id}`).then(resp => {
        this.cliente = resp.cliente.toUpperCase()
      }).catch(() => {
        erro()
      })
    },
    gravar () {
      let body = this.venda
      body.usuario_id = this.venda.usu_id2 ? this.venda.usu_id2 : this.user.id
      body.clientes_id = this.$route.params.id
      body.USER = this.user.id

      if (this.$route.params.idVenda) {
        this.$axios.put(`/vendas/${this.$route.params.idVenda}`, body).then(() => {
          this.getLogsTotal(this.user.id)
          switch (this.envio) {
            case 'Agenda':
              this.$router.push(`/sistema/clientes/${this.$route.params.id}/agenda`)
              return
            case 'Vendas':
              this.$router.push(`/sistema/vendas`)
              return
            case 'Cliente':
              this.$router.push(`/sistema/clientes/${this.$route.params.id}`)
            // eslint-disable-next-line no-fallthrough
            default:
              this.$router.push(`/sistema/vendas`)
          }
        })
      } else {
        this.$axios.post('/vendas', body).then(() => {
          this.getLogsTotal(this.user.id)
          switch (this.envio) {
            case 'Agenda':
              this.$router.push(`/sistema/clientes/${this.$route.params.id}/agenda`)
              return
            case 'Vendas':
              this.$router.push(`/sistema/vendas`)
              return
            case 'Cliente':
              this.$router.push(`/sistema/clientes/${this.$route.params.id}`)
            // eslint-disable-next-line no-fallthrough
            default:
              this.$router.push(`/sistema/vendas`)
          }
        })
      }
    },
    selecionar (produto) {
      this.produtosSelecionados = produto
    },
    setCategoria (categoria) {
      this.venda.categoria_id = categoria
    },
    verifPermissao () {
      if (this.$route.params.idVenda) {
        if (!this.ACTION.ev) {
          this.$router.push('/erro')
        }
      }
    }
  },
  created () {
    this.getCliente()
  },
  async mounted () {
    this.verifPermissao()
    if (this.$route.params.idVenda) {
      this.isActive = true
      await this.getVenda()
      setTimeout(async () => {
        await this.print()
      }, 1e3);
    }
  }
}
</script>

<style lang="stylus">
@media print {
   .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
        float: left;
   }
   .col-sm-12 {
        width: 100%;
   }
   .col-sm-11 {
        width: 91.66666667%;
   }
   .col-sm-10 {
        width: 83.33333333%;
   }
   .col-sm-9 {
        width: 75%;
   }
   .col-sm-8 {
        width: 66.66666667%;
   }
   .col-sm-7 {
        width: 58.33333333%;
   }
   .col-sm-6 {
        width: 50%;
   }
   .col-sm-5 {
        width: 41.66666667%;
   }
   .col-sm-4 {
        width: 33.33333333%;
   }
   .col-sm-3 {
        width: 25%;
   }
   .col-sm-2 {
        width: 16.66666667%;
   }
   .col-sm-1 {
        width: 8.33333333%;
   }
}
</style>
